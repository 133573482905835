/* this file is transformed by vux-loader */
/* eslint-disable */
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      busAllList: [],
      //全部车辆
      Info: [],
      //总信息
      pageIndex: 1,
      //
      pageSize: 10,
      //条数
      totalRecord: 10,
      //总条数
      totalPage: 1,
      //总页数
      loading: false,
      //加载的图标
      finished: false,
      //结束加载的标志
      error: false //发生错误了

    };
  },
  methods: {
    onLoad: function onLoad() {
      // 正在载入
      this.loading = true;

      if (this.pageIndex <= this.totalPage) {
        this.getAllBusList();
      }
    },
    getAllBusList: function getAllBusList() {
      var _this = this;

      this.$utils.Tools.loadShow();
      var params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      this.$api.Bus.selectAllBusList(params).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          var _this$busAllList;

          _this.Info = data;
          _this.totalPage = data.totalPage;

          if (_this.pageIndex < data.totalPage) {
            _this.pageIndex++;
          }

          _this.totalRecord = data.totalRecord;

          (_this$busAllList = _this.busAllList).push.apply(_this$busAllList, _toConsumableArray(data.records));

          if (_this.busAllList && _this.busAllList.length >= data.totalRecord) {
            _this.finished = true;
          }
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    this.$utils.Store.removeItem('busbh');
  }
};